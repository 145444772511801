import React from "react";
import LazyLoad from "react-lazyload";
import { graphql } from "gatsby";
import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { DocumentStyle } from "~components/templates/document-style";
import { Section } from "~components/templates/section";
import { Button } from "~components/utils/button";
import { Link } from "gatsby";
import { AssessmentArticleList } from "~components/articles/assessment-article-list";
import "./index.scss";

type Props = {
  data: GatsbyAssessmentIoTPageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <Assessment2PaneLayout
      formId={CONTACT_FORM_ID}
      meta={{
        title: "IoT診断",
        description:
          "IoT診断ではお客様のご要望に応じて、IoT機器およびIoT機器と接続される環境に潜在する脆弱性を洗い出します。専門のセキュリティエンジニアが通信や認証システム、ハードウェアなどにセキュリティリスクがないか網羅的に診断します。",
      }}
    >
      <div className="IoT AssessmentCommonPage">
        <LazyLoad>
          <div className="AssessmentCommonTop IoTTop">
            <div className="AssessmentCommonTop_Wrapper">
              <Common2PaneSectionLayout>
                <DocumentStyle align="center" width="no-limit">
                  <h1>
                    Io<span className="IoTTop_Kerning">T診断</span>
                  </h1>
                  <div className="AssessmentCommonTop_Body">
                    インターネット技術や各種センサー・テクノロジーといった技術が進歩する現在では、様々なハードウェアがインターネットに接続して動作するIoT(Internet
                    of
                    Things)が急速に普及しています。一方、IoT機器へのセキュリティ対策の取り組みは開発側、運用側共に不十分な傾向があります。
                    <br />
                    <br />
                    IoT診断ではお客様のご要望に応じて、IoT機器およびIoT機器と接続される環境に潜在する脆弱性を洗い出します。専門のセキュリティエンジニアが通信や認証システム、ハードウェアなどにセキュリティリスクがないか網羅的に診断します。
                  </div>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </div>
          </div>
        </LazyLoad>

        <BreadCrumbs title="IoT" />

        <Section>
          <div className="IoT_TextWrapper">
            <h2>
              Flatt SecurityのIo<span className="IoT_Kerning">T診断</span>
            </h2>
            <h3>多様な診断プランを実施可能</h3>
            <p>
              {`IoT機器のみならず、機器と連携されるWebやスマートフォンのアプリケーション、接続されるネットワーク環境などの診断も合わせて実施する事で総合的なリスク把握を行う事ができます。

                  お客様のご予算、ご要望および診断対象の特性に合わせた最適な診断プランを多くの選択肢の中からご提案します。`}
            </p>
            <h3>網羅的な診断</h3>
            <p>
              Flatt
              Securityでは、開発・診断共に経験豊富なエンジニアが診断する事で網羅的にセキュリティリスクを洗いだす事を可能にしています。
            </p>
            <h3>IoT機器のセキュリティ評価を把握</h3>
            <p>
              {`診断対象ごとに機能を分析し、検査項目を洗い出しセキュリティリスクを診断します。診断報告書ではセキュリティリスクの深刻度の評価を行い、総合的な評価および機能ごとの評価の把握が可能になります。

                  セキュリティリスクの深刻度の評価は脆弱性の有無や検出された脆弱性の緊急性によって5段階で評価いたします。`}
            </p>
            <h3>様々なリスクの低減</h3>
            <p>
              {`診断後の報告書ではセキュリティリスクへの今後の対策方法を詳細に記載いたします。
                  報告書を基にセキュリティ対策を講じる事で、ベンダーのお客様は機器の脆弱性を悪用したサイバー攻撃から利用者を守り、商品のリコールや会社のイメージダウンなどのリスクを低減できます。

                  IoT機器を使用するユーザのお客様は、IoT機器の乗っ取りや個人情報の漏洩といったリスクを低減する事ができます。`}
            </p>
          </div>
        </Section>

        <Section background="light-gray">
          <div className="Process_Wrapper">
            <h2 className="Process_Heading">診断の手順</h2>
            <ul className="Process_StepList">
              <li className="Process_StepWrapper">
                <div className="Process_Step">
                  <h3 className="Process_Number">1</h3>
                  <div className="Process_Container">
                    <h2 className="Process_SubHeading">診断準備</h2>
                    <div className="Process_Note note">
                      NDA締結後、事前ヒアリングを行い最適な診断プランをご提案いたします。その後、お客様のご要望、診断対象の機能に沿った診断内容を把握し、お見積もりをさせていただきます。
                      <br />
                      <br />
                      お見積金額をご確認いただいた上で納期や診断に関するルールを決定し、契約を締結します。
                    </div>
                  </div>
                </div>
                <img
                  className="Process_Triangle"
                  src={data?.triangle?.publicURL || ""}
                  alt=""
                />
              </li>
              <li className="Process_StepWrapper">
                <div className="Process_Step">
                  <h3 className="Process_Number">2</h3>
                  <div className="Process_Container">
                    <h2 className="Process_SubHeading">診断実施</h2>
                    <div className="Process_Note note">
                      専門のセキュリティエンジニアが診断対象の特性や機能に合わせた専用のツールと手動での調査を組み合わせ、網羅的に対象を診断します。診断結果は診断完了後に報告書にまとめ、pdfで納品します。
                      <br />
                      <br />
                      特に緊急性の高い脆弱性が検出された場合、ご希望された方には当日速報をお出ししてご連絡差し上げます。
                    </div>
                  </div>
                </div>
                <img
                  className="Process_Triangle"
                  src={data?.triangle?.publicURL || ""}
                  alt=""
                />
              </li>
              <li className="Process_StepWrapper">
                <div className="Process_Step">
                  <h3 className="Process_Number">3</h3>
                  <div className="Process_Container">
                    <h2 className="Process_SubHeading">診断実施後</h2>
                    <div className="Process_Note note">
                      実施結果をもとに作成した報告書を納品します。報告書には脆弱性の概要、再現方法、診断項目、診断結果、今後の対策方法など詳しく記載いたします。
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Section>

        <LazyLoad>
          <Section background="brand">
            <Common2PaneSectionLayout>
              <DocumentStyle>
                <h2>料金</h2>
              </DocumentStyle>
            </Common2PaneSectionLayout>
            <div className="Fee_Wrapper wow fadeInUp">
              <h4 className="Fee_Title">IoT診断</h4>
              <h2 className="Fee_Price">
                50
                <span className="Fee_SmallText">万円から</span>
              </h2>
            </div>
            <Common2PaneSectionLayout>
              <DocumentStyle>
                <p>
                  {`IoT診断は、対象とする機器・システムの規模などによって費用が変わります。
                  最終的な金額は無料のお見積もりによって決定いたしますので、ご興味のある方は下記フォームよりお気軽にお問い合わせください。`}
                </p>
              </DocumentStyle>
            </Common2PaneSectionLayout>
          </Section>
        </LazyLoad>
        <AssessmentArticleList
          data={{
            articles: data.allContentfulArticle.nodes,
            category: data.contentfulArticleCategory,
          }}
        />

        <h2 className="AssessmentCommonContact_Title">
          お見積もりは無料です。診断のご依頼はお気軽にお問い合わせください。
        </h2>

        <Link to="/contact">
          <div className="AssessmentCommonQuickContact">
            <Button buttonTheme="Secondary" buttonSize="Large">
              今すぐお問い合わせ
            </Button>
          </div>
        </Link>
      </div>
    </Assessment2PaneLayout>
  );
};

export const query = graphql`
  query AssessmentIoTPage {
    triangle: file(relativePath: { eq: "assessment/triangle.png" }) {
      publicURL
    }
    allContentfulArticle(
      filter: { category: { slug: { eq: "iot" } }, slug: { ne: "disabled" } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...AssessmentArticleListItemData
      }
    }
    contentfulArticleCategory(slug: { eq: "iot" }) {
      ...AssessmentArticlaListCategory
    }
  }
`;

export default withContext(Component);
